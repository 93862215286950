<template>
  <section class="bet_group">
    <div v-if="betsData.length < 1 && !loadingGames">
      <NoData
        :title="$t('noAvailableBets')"
        :description="$t('youDontHaveBet')"
        :cta="$t('placeABet')"
        noDataTo="/bet-slip"
      />
    </div>
    <div v-if="!loadingGames && betsData && betsData.length > 0">
      <div
        v-for="(bet, idx) in betsData"
        :key="bet.id + idx"
        class="date-group"
      >
        <div>
          <div v-if="isActive('games')" class="bet-item">
            <div class="box_one">
              <p>
                {{ bet.game_name }}
              </p>
              <div class="statuses">
                <span
                  :class="
                    getStatusNameCasino(bet) === `${$t('won')}`
                      ? 'won'
                      : getStatusNameCasino(bet) === `${$t('lost')}`
                      ? 'lost'
                      : 'pending'
                  "
                >
                  {{ getStatusNameCasino(bet) }}
                </span>
              </div>
              <p>
                {{ $t("stake") }}:
                <span class="bold"
                  >{{ comaFormated(bet.stake, lang) }} {{ fiatCurrency }}</span
                >
              </p>
            </div>
            <div class="box_two">
              <p>{{ formatDateTime(bet.created) }}</p>
            </div>
          </div>
          <div v-else class="bet-item" @click="handleDetailsScreen(bet)">
            <div class="box_one">
              <div class="statuses">
                <span
                  :class="
                    getStatusName(bet) === `${$t('cancelled')}`
                      ? 'cancelled'
                      : getStatusName(bet) === `${$t('won')}`
                      ? 'won'
                      : getStatusName(bet) === `${$t('lost')}`
                      ? 'lost'
                      : getStatusName(bet) === `${$t('pending')}`
                      ? 'pending'
                      : getStatusName(bet) === `${$t('voided')}`
                      ? 'voided'
                      : getStatusName(bet) === `${$t('cashedout')}`
                      ? 'cashout'
                      : 'closed'
                  "
                >
                  {{ getStatusName(bet) }}
                </span>
              </div>
              <div>
                <h3>{{ $t("betId") }} {{ bet.code }}</h3>
                <p v-if="bet.status === 5">
                  {{ $t("cashoutValue") }} :
                  <span class="bold"
                    >{{ comaFormated(bet.cashout_value, lang) }}
                    {{ fiatCurrency }}</span
                  >
                </p>
                <p v-else>
                  {{ $t("possiblePayout") }} :
                  <span class="bold"
                    >{{ comaFormated(bet.possible_win, lang) }}
                    {{ fiatCurrency }}</span
                  >
                </p>
              </div>
            </div>
            <div class="box_two">
              <div>
                <p
                  v-if="bet.status === 0 && active_menu === 'sports'"
                  class="cashout_availble"
                >
                  {{ $t("cashoutAvailble") }}
                </p>
              </div>
              <!-- <h3></h3> -->
              <p class="date">{{ formatDateTime(bet.created) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NoData from "@/components/ui/NoData.vue";
import { comaFormated } from "@/utils/formatter";
export default {
  name: "MyBetList",
  data: function () {
    return {};
  },
  props: {
    active_menu: {
      type: String,
      required: true,
    },
    betsData: {
      type: Array,
      required: true,
    },
    loadingGames: {
      type: Boolean,
      required: true,
    },
    isActive: {
      type: Function,
      required: true,
    },
    getStatusNameCasino: {
      type: Function,
      required: true,
    },
    getStatusName: {
      type: Function,
      required: true,
    },
    formatDateTime: {
      type: Function,
      required: true,
    },
    handleDetailsScreen: {
      type: Function,
      required: true,
    },
  },
  components: {
    NoData,
    // Cashout,
  },
  methods: {
    comaFormated,
  },
  watch: {
    betsData() {
      // console.log(this.betsData, "betsDatabetsDatabetsDatabetsDatabetsData");
    },
  },
};
</script>

<style scoped src="./index.css"></style>
