<template>
  <div class="details">
    <section class="bet_group page_container">
      <div class="bet-item">
        <div class="box_one">
          <div class="statuses">
            <span
              :class="
                getStatusName(bet) === `${$t('cancelled')}`
                  ? 'cancelled'
                  : getStatusName(bet) === `${$t('won')}`
                  ? 'won'
                  : getStatusName(bet) === `${$t('lost')}`
                  ? 'lost'
                  : getStatusName(bet) === `${$t('pending')}`
                  ? 'pending'
                  : getStatusName(bet) === `${$t('voided')}`
                  ? 'voided'
                  : getStatusName(bet) === `${$t('cashedout')}`
                  ? 'cashout'
                  : 'closed'
              "
            >
              {{ getStatusName(bet) }}
            </span>
          </div>
          <h3>{{ $t("betId") }}{{ bet?.code }}</h3>
        </div>
        <div class="box_two">
          <p>{{ formatDateTime(bet?.created) }}</p>
        </div>
      </div>
    </section>
    <section class="page_container">
      <div class="overview_flex">
        <span>{{ $t("totalOdds") }}:</span>
        <span>{{ bet?.total_odd.toFixed(2) }}</span>
      </div>
      <div class="overview_flex">
        <span>{{ $t("stake") }}:</span>
        <span>{{ bet?.stake }} {{ fiatCurrency }}</span>
      </div>
      <div class="overview_flex">
        <span>{{ $t("eventWon") }}:</span>
        <span>{{ bet?.won_games }} / {{ bet?.picked.length }}</span>
      </div>
      <div class="overview_flex">
        <span>{{ $t("possibleWin") }}:</span>
        <span
          >{{ comaFormated(bet?.possible_win, lang) }}{{ " "
          }}{{ fiatCurrency }}</span
        >
      </div>
      <div v-if="bet.status === 5" class="overview_flex">
        <span>{{ $t("cashedout") }}:</span>
        <span
          >{{ comaFormated(bet?.cashout_value, lang) }}{{ " "
          }}{{ fiatCurrency }}</span
        >
      </div>
      <div v-if="getStatusName(bet) === `${$t('won')}`">
        <!-- <div class="overview_flex">
          <span>{{ $t("eventWon") }}:</span>
          <span> -->
        <!-- {{ comaFormated(bet?.possible_win, lang) }}{{ " "
            }}{{ fiatCurrency }} -->
        <!-- {{ bet?.picked.length }}/{{ bet?.picked.length }} -->
        <!-- </span>
        </div> -->
        <!-- <div class="overview_flex">
          <span>Excise Tax:</span>
          <span
            >{{ comaFormated(bet?.tax_on_stake, lang) }}{{ " "
            }}{{ fiatCurrency }}</span
          >
        </div> -->
        <!-- <div class="overview_flex">
          <span>Withholding Tax:</span>
          <span
            >{{ comaFormated(bet?.tax_on_winning, lang) }}{{ " "
            }}{{ fiatCurrency }}</span
          >
        </div> -->
      </div>
    </section>
    <div class="bet_head">
      <div class="page_container">
        <span>{{ $t("bets") }}</span>
      </div>
    </div>
    <div
      v-for="(picked, index) in bet?.picked"
      :key="bet.id + index"
      class="bet_item"
    >
      <div class="bet_details page_container">
        <div class="box_one">
          <div class="date">
            <span>{{
              picked?.match_date && formatDateTime(picked?.match_date)
            }}</span>
            <span v-if="picked.fixture_status" class="match_status">{{
              picked.fixture_status.match_status
            }}</span>
          </div>
          <div
            v-if="
              picked.fixture_status.away_score &&
              picked.fixture_status.away_score !== '-'
            "
            class="result"
          >
            <p>
              {{ getHomeCompetitorName(picked.match_name) }}
            </p>
            <div class="score">
              <span>{{ picked.fixture_status.home_score }} </span>
              <span>:</span>
              <span>{{ picked.fixture_status.away_score }} </span>
            </div>
            <p>
              {{ getAwayCompetitorName(picked.match_name) }}
            </p>
          </div>
          <p v-else>
            {{ picked.match_name }}
          </p>
          <span> {{ picked.tournament }}</span>

          <span
            >{{ $t("yourPick") }}: {{ picked?.market_name }} •
            {{ picked.outcome_name }}</span
          >
        </div>
        <div class="box_two">
          <p>{{ picked?.odd }}</p>
          <span
            :class="
              getStatusNameForPick(picked) === `${$t('won')}`
                ? 'bet_status won'
                : getStatusNameForPick(picked) === `${$t('pending')}`
                ? 'bet_status pending'
                : getStatusNameForPick(picked) === `${$t('lost')}`
                ? 'bet_status lost'
                : getStatusNameForPick(picked) === `${$t('void')}`
                ? 'bet_status void'
                : getStatusNameForPick(picked) === `${$t('cashedout')}`
                ? 'bet_status cashout'
                : 'bet_status'
            "
          >
          </span>
        </div>
      </div>
    </div>

    <div class="page_container">
      <div class="rules">
        <span>{{ $t("betPlaced") }} {{ formatDateTime(bet.created) }}</span>
        <p>{{ $t("maximumWinnings") }} {{ fiatCurrency }}</p>
        <div>
          <p>
            <span class="bet_status pending"></span>
            <span>{{ $t("pending") }}</span>
          </p>
          <p>
            <span class="bet_status won"></span> <span>{{ $t("won") }}</span>
          </p>
          <p v-if="active_menu === 'sports'">
            <span class="bet_status cashout"></span>
            <span>{{ $t("cashedout") }}</span>
          </p>
          <p>
            <span class="bet_status void"></span>
            <span>{{ $t("voided") }}</span>
          </p>
          <p>
            <span class="bet_status cancelled"></span>
            <span>{{ $t("cancelled") }}</span>
          </p>
          <p>
            <span class="bet_status lost"></span> <span>{{ $t("lost") }}</span>
          </p>
        </div>
      </div>
      <!-- <div class="action_groups">
        <div v-if="getStatusName(bet) !== `${$t('cashout')}`">
          <ChopbetButton variant="Primary"
            >{{ $t("requestCashout") }}
          </ChopbetButton>
        </div>
      </div> -->
      <div
        class="request_cashout"
        v-if="bet.status === 0 && active_menu === 'sports'"
      >
        <ChopbetButton
          class="request_btn"
          @click="handleRequest()"
          :loading="loading"
        >
          {{ $t("requestCashout") }}
        </ChopbetButton>
        <div class="share">
          <ChopbetButton @click="handleShare" variant="outline">
            {{ $t("share") }}
          </ChopbetButton>
          <ChopbetButton @click="handleRebet" variant="outline">
            {{ $t("rebet") }}
          </ChopbetButton>
        </div>
      </div>
    </div>
    <ChopbetSlideUp :isOpen="isSlideUpOpen" @closeSlideup="closeSlideUp">
      <span @click="closeSlideUp" class="close"><CancelIcon /></span>
      <div class="cashout_modal">
        <h5>{{ $t("requestCashout") }}</h5>
        <div class="request_amount">
          <div class="amount">
            <p>{{ $t("stakeAmount") }}:</p>
            <p>
              <span>{{ fiatCurrency }} </span> {{ bet.stake.toFixed(2) }}
            </p>
          </div>
          <div class="amount">
            <p>{{ $t("possibleWin") }}:</p>
            <p>
              <span>{{ fiatCurrency }} </span> {{ bet.possible_win.toFixed(2) }}
            </p>
          </div>
        </div>

        <!-- <p v-if="allowResendTimer" class="expire">
          Expires in <span>10 secs</span>
        </p> -->
        <!-- <p v-else class="expire">Expires in <span>10 secs</span></p> -->
        <!-- <div > -->
        <p v-if="allowResendTimer" class="expire">
          {{ $t("expiresIn")
          }}<span> {{ this.resendSecond }} {{ $t("seconds") }}</span>
        </p>
        <p v-else class="expired">{{ $t("cashoutRequestExpired") }}</p>
        <ChopbetButton
          class="request_btn"
          @click="confirmRequest()"
          :loading="confirmLoading"
          :disabled="!allowResendTimer"
          variant="cashout"
        >
          {{ $t("confirmCashout") }}
          {{
            allowResendTimer ? `${fiatCurrency} ${cashoutValue.toFixed(2)}` : ""
          }}
        </ChopbetButton>
      </div>
    </ChopbetSlideUp>
    <ChopbetSlideUp :isOpen="isShareSlideOpen" @closeSlideup="closeShareSlide">
      <div class="share_modal">
        <div class="cancel">
          <button @click="closeShareSlide"><CancelIcon /></button>
        </div>
        <div class="socials_wrap">
          <p>{{ $t("shareYourBetslip") }}</p>
          <p class="des">
            {{ $t("shareOnAnySocial") }}
          </p>
          <div class="socials">
            <button @click="copyShare">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
              >
                <path
                  d="M7.93691 10.6C6.73209 9.39041 6.84089 7.31997 8.17931 5.97675L12.0578 2.08389C13.3962 0.739863 15.4578 0.631061 16.6635 1.83988C17.8691 3.0487 17.7595 5.11994 16.421 6.46396L14.4818 8.41039"
                  stroke="var(--text-color)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M11.0632 7.40039C12.268 8.61001 12.1592 10.6804 10.8208 12.0237L8.88155 13.9701L6.94232 15.9165C5.6039 17.2606 3.54226 17.3694 2.33664 16.1605C1.13102 14.9517 1.24063 12.8805 2.57905 11.5365L4.51828 9.59003"
                  stroke="var(--text-color)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </button>
            <button @click="shareOnFacebook">
              <Facebook />
            </button>
            <button @click="shareOnWhatsApp">
              <Whatsapp />
            </button>
            <button @click="shareOnTelegram">
              <Telegram />
            </button>
          </div>
        </div>
        <div class="booking_code">
          <p>{{ $t("bookingCode") }}</p>
          <div>
            <p>{{ booking_code }}</p>
            <button class="clipboard" @click="copyToClipboard">
              <CopyIcon />
            </button>
          </div>
        </div>
      </div>
    </ChopbetSlideUp>
  </div>
</template>

<script>
import ChopbetSlideUp from "@/components/ui/ChopbetSlideUp.vue";
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import CancelIcon from "@/components/icons/CancelIcon.vue";
import {
  comaFormated,
  formatBetStatusOrOutcome,
  formatDateTime,
} from "../../../utils/formatter";
import { confirmCashout, requestCashout } from "../../../actions/bet";
import CopyIcon from "@/components/icons/CopyIcon.vue";
import Facebook from "@/components/icons/Facebook.vue";
import Whatsapp from "@/components/icons/Whatsapp.vue";
import Telegram from "@/components/icons/Telegram.vue";

export default {
  name: "MyBetDetails",
  data: function () {
    return {
      lang: this.$i18n.locale,
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      isSlideUpOpen: false,
      loading: false,
      confirmLoading: false,
      activeCashout: false,
      cashoutValue: 0,
      booking_code: this.bet.code,
      shareLink: "",
      isShareSlideOpen: false,
      allowResend: false,
      allowResendTimer: true,
      resendSecond: 10,
    };
  },
  mounted() {
    console.log(this.bet, "picked");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  props: {
    active_menu: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    bet: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    getStatusNameCasino: {
      type: Function,
      required: true,
    },
    getStatusName: {
      type: Function,
      required: true,
    },
    myBets: {
      type: Function,
      required: true,
    },
    bet_type: {
      require: true,
    },
  },
  components: {
    Telegram,
    Facebook,
    Whatsapp,
    CopyIcon,
    ChopbetSlideUp,
    ChopbetButton,
    CancelIcon,
  },
  methods: {
    comaFormated,
    formatDateTime,
    formatBetStatusOrOutcome,
    getStatusNameForPick: function (picked) {
      if (picked.won === 1) {
        return `${this.$t("won")}`;
      }
      if (picked.won === 0) {
        return `${this.$t("lost")}`;
      }
      if (picked.status === -1) {
        return `${this.$t("cancelled")}`;
      }
      if (picked.status === 0) {
        return `${this.$t("pending")}`;
      }
      if (picked.status === 1) {
        return `${this.$t("lost")}`;
      }
      if (picked.status === 2) {
        return `${this.$t("won")}`;
      }
      if (picked.status === 3) {
        return `${this.$t("voided")}`;
      }
      if (picked.status === 5) {
        return `${this.$t("cashedout")}`;
      }
      // if (picked.won === 1) {
      //   return `${this.$t("won")}`;
      // }
      // if (picked.won === 0) {
      //   return `${this.$t("lost")}`;
      // }
      return "";
    },

    changePage() {
      const newPage = 1;
      this.$emit("updatePage", newPage);
    },
    async handleOnClose() {
      this.onClose();
    },
    getHomeCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }

      return matchName.split("vs.")[0];
    },
    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },
    async confirmRequest() {
      const { id } = this.bet;
      this.confirmLoading = true;
      this.activeCashout = false;
      const apiKey = this.getAuth();
      var params = { apiKey, bet_id: id, amount: this.cashoutValue };
      const res = await confirmCashout(params);
      const { cashout_value } = res.data;
      if (res.success) {
        this.$emit("updatePage", 1);
        this.setSuccess(`${this.$t("cashoutSuccess")} ${cashout_value}!`);
        this.confirmLoading = false;
        this.closeSlideUp();
        this.close();
      } else {
        const errorMessage =
          res.error?.error_message || "An unknown error occurred";
        this.setError(errorMessage);
        this.confirmLoading = false;
        if (res.error?.status === 401) {
          this.logout();
          return;
        }
      }
    },
    handleRequest() {
      this.fetchRequest();
      this.startCountdown();
    },
    async fetchRequest() {
      const { id } = this.bet;
      this.loading = true;
      this.activeCashout = false;

      const apiKey = this.getAuth();
      const params = { apiKey, bet_id: id };

      const res = await requestCashout(params);
      if (res.success) {
        this.cashoutValue = res.data.cashout_value;
        this.activeCashout = true;
        this.startCountdown();
        this.isSlideUpOpen = true;
      } else {
        const errorMessage =
          res.error?.error_message || "An unknown error occurred";
        this.setError(errorMessage);
        if (res.error?.status === 401) {
          this.logout();
          return;
        }
      }
      this.loading = false;
    },
    handleShare() {
      this.isShareSlideOpen = true;
    },
    handleRebet() {
      this.$router.push(`/bet-slip/${this.booking_code}`);
    },
    closeSlideUp() {
      this.isSlideUpOpen = false;
    },
    closeShareSlide() {
      this.isShareSlideOpen = false;
    },
    handleFetchError(err) {
      console.log(err, "errrr");

      this.loading = false;
      let message = "";
      if (err.response) {
        message = err.response.data.error_message || message;
        const status = parseInt(err.response.status);
        // if (status === 422) {
        //   this.setError(message);
        //   // this.hasSuspendedPicks = true;
        //   const suspendedMatch = err.response.data.data;
        //   const numbersArray = suspendedMatch
        //     .substring(1, suspendedMatch.length - 1)
        //     .split(" ")
        //     .map(Number);
        //   numbersArray.forEach((match) => {
        //     this.suspendedGames.push(this.betslip.picks[match]);
        //     this.betslip.picks[match].status = -1;
        //   });
        //   return;
        // }
        if (status === 428) {
          this.setError(message);
          return;
        }
        if (status === 401) {
          this.setError(message);
          this.logout();
          return;
        }
        this.setError(message);
      }
      // this.loading = false;
      // if (error.response) {
      //   console.log(error, "reponsereponsereponsereponsereponse");
      //   if (parseInt(error.response.data.status) === 401) {
      //     this.setError(`${this.$t("sessionExpired")}`);
      //     this.logout();
      //     return;
      //   }
      //   this.setError(error.response.data.error_message);
      // }
    },
    startCountdown() {
      this.resendSecond = 10;
      this.allowResend = false;
      this.allowResendTimer = true;

      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }
      this.countdownInterval = setInterval(() => {
        this.resendSecond--;
        if (this.resendSecond === 0) {
          clearInterval(this.countdownInterval);
          this.allowResend = true;
          this.allowResendTimer = false;
        }
      }, 1000);
    },
    shareOnFacebook() {
      const code = this.booking_code;
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/bet-slip/${code}`;
      window.open(facebookUrl, "_blank");
    },
    shareOnTelegram() {
      const code = this.booking_code;
      this.closeSlideUp();
      const telegramWebUrl = `https://t.me/share/url?url=${encodeURIComponent(
        window.location.origin + "/bet-slip/" + code
      )}`;
      window.open(telegramWebUrl, "_blank");
    },
    shareOnWhatsApp() {
      const code = this.booking_code;
      const whatsappUrl = `https://api.whatsapp.com/send?text=${window.location.origin}/bet-slip/${code}`;
      window.open(whatsappUrl, "_blank");
    },
    async copyShare() {
      try {
        const code = this.booking_code;
        const fullUrl = `${window.location.origin}/bet-slip/${code}`;
        await navigator.clipboard.writeText(fullUrl);
        this.setSuccess("Share link copied to clipboard!");
      } catch (err) {
        this.setError("Failed to copy text!");
      }
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.booking_code);
        this.setSuccess("Text copied to clipboard!");
      } catch (err) {
        this.setError("Failed to copy text!");
      }
    },
  },
};
</script>

<style scoped src="./index.css"></style>
